import React from 'react';
import {
    Container,
    CloseButton,
    ListGroup
} from 'react-bootstrap';
import CONSTANTS from '../constants';
import { getReferencias, getRelated } from '../utils/utils';

export default function PopupFicha({ ficha, opcoes, onChange }) {
    const referencias = getReferencias(ficha.id_tribo);
    const related = {};
    for (let relatedTaxId of CONSTANTS.TAXONOMIAS_FILTROS[0].relatedTaxes) {
        const recorte = CONSTANTS.RECORTES_TERRITORIAIS[relatedTaxId];
        related[relatedTaxId] = getRelated(
            ficha.id_tribo,
            relatedTaxId,
            recorte.manyToMany.field,
            recorte.idField,
            recorte.nomeField
        );
    }
    // console.log(ficha, referencias, related);

    return (
        <Container id="popupFicha">
            <CloseButton />
            <h1>
                {ficha.nome}
            </h1>

            {ficha.outros_nomes &&
                <p><strong>Outros nomes:</strong> {ficha.outros_nomes}</p>
            }
            {ficha.familia &&
                <p><strong>Família linguística:</strong> <span className={`familia familia_${ficha.id_familia_linguistica}`}>{ficha.familia}</span></p>
            }
            {ficha.tipo_tribo &&
                <p><strong>Situação da etnia/povo:</strong> {ficha.tipo_tribo}</p>
            }
            {ficha.tipo_sede &&
                <p><strong>Situação da sede:</strong> {ficha.tipo_sede}</p>
            }
            {ficha.datacao_inicial &&
                <p><strong>Ano de datação inicial:</strong> {ficha.datacao_inicial}</p>
            }
            {ficha.datacao_final &&
                <p><strong>Ano de datação final:</strong> {ficha.datacao_final}</p>
            }
            {ficha.descricao_local &&
                <p><strong>Referência de lugar:</strong> {ficha.descricao_local}</p>
            }
            {Object.keys(related).map((relatedTaxId) => (
                <>
                {related[relatedTaxId] && related[relatedTaxId].length > 0 &&
                    <p>
                        <strong>
                            {related[relatedTaxId].length === 1
                                ? CONSTANTS.RECORTES_TERRITORIAIS[relatedTaxId].nome
                                : CONSTANTS.RECORTES_TERRITORIAIS[relatedTaxId].plural
                            }
                        </strong>: {related[relatedTaxId].join(', ')}
                    </p>
                }
                </>
            ))}
            {referencias.length > 0 &&
                <>
                    <p>
                        <strong>{referencias.length === 1 ? 'Referência' : 'Referências'}</strong>:
                    </p>
                    <ListGroup>
                        {referencias.map((referencia) => (
                                <>
                                {referencia.link
                                    ? <ListGroup.Item action href={referencia.link} target="_blank" alt="clique para acessar referência" title="clique para acessar referência">
                                        {referencia.referencia_completa}
                                    </ListGroup.Item>
                                    : <ListGroup.Item>
                                        {referencia.referencia_completa}
                                    </ListGroup.Item>
                                }
                                </>
                        ))}
                    </ListGroup>
                </>
            }

        </Container>
    );
}
