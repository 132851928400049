import SETTINGS from './config';
const modoVisualizacao = localStorage.getItem('modoVisualizacao');
const modoMapa = localStorage.getItem('modoMapa');

const geoServerUrl = "http://portal.iphan.gov.br/geoserver";
// const geoServerUrl = "http://geoserver.homologacao.iphan.gov.br/geoserver";
// const restUrl = `${geoServerUrl}/Nimuendaju/wfs?version=2.0.0&typeName=Nimuendaju:tg_tribo_view&maxFeatures=500000&SERVICE=WFS&VERSION=1.0.0&REQUEST=GetFeature&TYPENAME=Nimuendaju:tg_tribo_view&SRSNAME=EPSG:4674&username=private_editor&password=iphandpi110&outputFormat=application%2Fjson`;

const CONSTANTS = {
    HOME: '',
    API: {
        mapaNimuendaju: `${geoServerUrl}/Nimuendaju`,
        media: '',
        taxonomia: '',
        taxonomias: '',
        iconesTemas: '',
    },
    MODO_MAPA: {
        MAPA: {
            id: 'MAPA',
            nome: 'Mapa',
            attrs: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        SATELITE: {
            id: 'SATELITE',
            nome: 'Satélite',
            attrs: '&copy; <a href="https://mapbox.com">MapBox</a>',
            url: `https://api.mapbox.com/styles/v1/dtygel/ckmu0up7v17wt17panh3u8d5e/tiles/{z}/{x}/{y}?access_token=${SETTINGS.MAPBOX_ACCESS_TOKEN}`
        },
        // ANTIQUE: {
        //     id: 'ANTIQUE',
        //     wms: true,
        //     nome: 'Mapa do Brasil de 1842 por Arrowsmith',
        //     attrs: 'Rumsey, D. and Arrowsmith, J. (2015). Brazil, By J. Arrowsmith. (Map) 49. London, Pubd. 15 Feby. 1842, by J Arrowsmith, 10 Soho Square (Raster Image). Cartography Associates.',
        //     url: 'https://geowebservices.stanford.edu/geoserver/wms',
        //     layers: 'druid:rq311cn2469',
        //     minZoom: 5,
        //     maxZoom: 9
        // }
    },
    WMS_MAPA: {
        id: 'WMS_MAPA',
        attrs: 'Mapa Etno-Histórico de Curt Nimuendaju. <a href="http://portal.iphan.gov.br/indl" target="_blank">IPHAN</a>. <a href="https://portal.ufpa.br/" target="_blank">UFPA</a>. Desenvolvido por <a href="https://eita.coop.br">Cooperativa EITA</a>',
        url: `${geoServerUrl}/Nimuendaju/wms`,
        layers: 'Nimuendaju:tg_tribo_view'
    },
    CAMADAS: {
        SITIO_ARQUEOLOGICO: {
            id: 'sitio_arqueologico',
            nome: 'Sítio Arqueológico',
            plural: 'Sítios Arqueológicos',
            // WMS: {
            //     id: 'sitio_arqueologico_WMS',
            //     attrs: 'Sítios Arqueológicos - IPHAN',
            //     url: `${geoServerUrl}/SICG/wms`,
            //     layers: 'SICG:sitios'
            // },
            fieldId: 'id_bem',
            popupFieldTitle: 'identificacao_bem',
            popupBody: [
                {
                    label: 'Classificação',
                    fieldName: 'ds_classificacao'
                },
                {
                    label: 'Natureza',
                    fieldName: 'ds_natureza'
                },
                {
                    label: 'Tipo de bem',
                    fieldName: 'ds_tipo_bem'
                },
                {
                    label: 'Data do cadastro',
                    fieldName: 'dt_cadastro',
                    date: true
                },
                {
                    label: 'Síntese',
                    fieldName: 'sintese_bem'
                },

            ]
        },
        BIOMA: {
            id: 'bioma',
            nome: 'Bioma',
            plural: 'Biomas',
            fieldId: 'ID',
            popupFieldTitle: 'BIO_NOM',
            popupBody: []
        },
        TI: {
            id: 'ti',
            nome: 'Território Indígena',
            plural: 'Territórios Indígenas',
            fieldId: 'ID',
            popupFieldTitle: 'NOM_TI_A',
            popupBody: [
                {
                    label: 'Etnia',
                    fieldName: 'ETNIA'
                },
                {
                    label: 'Município',
                    fieldName: 'MUN'
                },
                {
                    label: 'UF',
                    fieldName: 'SIGLA_UF'
                },
                {
                    label: 'Modalidade',
                    fieldName: 'MODALID'
                },
                {
                    label: 'Fase',
                    fieldName: 'FASE'
                },
                {
                    label: 'Área (km²)',
                    fieldName: 'AREAKM2',
                    float: true
                }
            ]
        },
        UC: {
            id: 'uc',
            nome: 'Unidade de Conservação',
            plural: 'Unidades de Conservação',
            fieldId: 'ID',
            popupFieldTitle: 'NOM_UC_C',
            popupBody: [
                {
                    label: 'Categoria',
                    fieldName: 'CATEG'
                },
                {
                    label: 'Instância',
                    fieldName: 'INSTANC'
                },
                {
                    label: 'Ano de criação',
                    fieldName: 'ANO'
                },
                {
                    label: 'Município',
                    fieldName: 'ETNIA'
                },
                {
                    label: 'UF',
                    fieldName: 'SIGLA_UF'
                },
                {
                    label: 'Bioma',
                    fieldName: 'BIOMA'
                },
                {
                    label: 'Bacia Hidrográfica',
                    fieldName: 'B_HIDRO'
                },
                {
                    label: 'Área (km²)',
                    fieldName: 'AREAKM2'
                },
                {
                    label: 'Gestão',
                    fieldName: 'GESTAO'
                },
            ]
        },
    },
    LOCALSTORAGE: {
        modoVisualizacao: modoVisualizacao,
        modoMapa: modoMapa
    },
    TIPOS_DE_FICHA: [
        {
            id: 1,
            slug: 'existente',
            active: true,
            name: 'Existente',
            plural: 'Existentes',
            cor: '#7774A2'
        },
        {
            id: 2,
            slug: 'extinta',
            active: true,
            name: 'Extinta',
            plural: 'Extintas',
            cor: '#63A5A3'
        }
    ],
    TAXONOMIAS_FILTROS: [
        {
            id: 'localizacao',
            slug: 'localizacao',
            label: 'Localização',
            relatedTaxes: ['mr', 'bioma', 'uf', 'uc', 'quadrantes', 'municipio'],
            placeholder: 'Localização'
        },
        {
            id: 'nome',
            slug: 'nome',
            idField: 'id_tribo',
            label: 'Etnia/Povo',
            placeholder: 'Etnia/Povo',
        },
        {
            id: 'familia',
            slug: 'familia',
            idField: 'id_familia_linguistica',
            label: 'Família linguística',
            placeholder: 'Família linguística',
        },
        {
            id: 'tipo_tribo',
            slug: 'tipo_tribo',
            idField: 'id_tipo_tribo',
            label: 'Situação da etnia/povo',
            placeholder: 'Situação da etnia/povo',
        },
        {
            id: 'tipo_sede',
            slug: 'tipo_sede',
            idField: 'id_tipo_sede',
            label: 'Situação da sede',
            placeholder: 'Situação da sede'
        },
        {
            id: 'datacaoInicial',
            slug: 'datacaoInicial',
            label: 'Datação Inicial',
            relatedTaxes: ['datacaoInicialSeculo', 'datacaoInicialDecada'],
            placeholder: 'Datação Inicial'
        },
        {
            id: 'datacaoFinal',
            slug: 'datacaoFinal',
            label: 'Datação Final',
            relatedTaxes: ['datacaoFinalSeculo', 'datacaoFinalDecada'],
            placeholder: 'Datação Final'
        },
        {
            id: 'referencias',
            slug: 'referencias',
            label: 'Referência',
            placeholder: 'Referência',
            manyToMany: {
                field: 'id_referencia',
                label: 'referencia_completa'
            }
        }
    ],
    RECORTES_TERRITORIAIS: {
        mr: {
            id: 'mr',
            nome: 'Macrorregião',
            plural: 'Macrorregiões',
            idField: 'sigla',
            nomeField: 'nome',
            manyToMany: {
                field: 'sigla_mr'
            }
        },
        uf: {
            id: 'uf',
            nome: 'Estado',
            plural: 'Estados',
            idField: 'ID',
            nomeField: 'NOME_AC',
            manyToMany: {
                field: 'id_uf'
            }
        },
        municipio: {
            id: "municipio",
            nome: "Município",
            plural: "Municípios",
            idField: "ID",
            nomeField: "NOME_AC",
            parentField: "UF_SIGLA",
            manyToMany: {
                field: 'id_municipio'
            }
        },
        bioma: {
            id: 'bioma',
            nome: 'Bioma',
            plural: 'Biomas',
            idField: 'ID',
            nomeField: 'BIO_NOM',
            manyToMany: {
                field: 'id_bioma'
            }
        },
        quadrantes: {
            id: 'quadrantes',
            slug: 'quadrantes',
            nome: 'Quadrante',
            plural: 'Quadrantes',
            idField: 'id_quadrante',
            nomeField: 'nome',
            manyToMany: {
                field: 'id_quadrante',
                label: 'nome'
            }
        },
        uc: {
            id: "uc",
            nome: "Unidade de Conservação",
            plural: "Unidades de Conservação",
            idField: "ID",
            nomeField: "NOM_UC_C",
            manyToMany: {
                field: 'id_uc'
            }
        },
        datacaoInicialSeculo: {
            id: "datacaoInicialSeculo",
            nome: "Século",
            plural: "Séculos"
        },
        datacaoInicialDecada: {
            id: "datacaoInicialDecada",
            nome: "Década",
            plural: "Décadas"
        },
        datacaoFinalSeculo: {
            id: "datacaoFinalSeculo",
            nome: "Século",
            plural: "Séculos"
        },
        datacaoFinalDecada: {
            id: "datacaoFinalDecada",
            nome: "Década",
            plural: "Décadas"
        },
        // bh1: {
        //     id: 'bh1',
        //     nome: 'Bacia Hidrográfica de 1ª grandeza',
        //     plural: 'Bacias Hidrográficas de 1ª grandeza',
        //     idField: 'BH1_NOMC',
        //     nomeField: 'BH1_NOM'
        // }
    },
    SEARCH_FIELDS: ['nome', 'outros_nomes', 'descricao_local'],
    FICHAS_PER_PAGE: 6,
    MAX_FICHAS_WMS_CQL: 750,
    PALETA_DE_CORES: ['#3366cc',  '#dc3912',  '#ff9900',  '#109618',  '#990099',  '#0099c6',  '#dd4477',  '#66aa00',  '#b82e2e',  '#316395',  '#3366cc',  '#994499',  '#22aa99',  '#aaaa11',  '#6633cc',  '#e67300',  '#8b0707',  '#651067',  '#329262',  '#5574a6',  '#3b3eac',  '#b77322',  '#16d620',  '#b91383',  '#f4359e',  '#9c5935',  '#a9c413',  '#2a778d',  '#668d1c',  '#bea413',  '#0c5922',  '#743411'],
    MACRORREGIOES_CEM: {
        NO: [],
        N: [],
        NE: [],
        CO: [],
        SE: [],
        S: []
    },
    MACRORREGIOES: {
        N: ['ac', 'ap', 'am', 'pa', 'ro', 'rr', 'to'],
        NE: ['al', 'ba', 'ce', 'ma', 'pb', 'pe', 'pi', 'rn', 'se'],
        CO: ['go', 'mt', 'ms', 'df'],
        SE: ['es', 'mg', 'rj', 'sp'],
        S: ['pr', 'sc', 'rs']
    }
};


CONSTANTS.emptyFilters = {
    modo: modoVisualizacao ? modoVisualizacao : 'panorama',
    q: '',
};
for (let tax of CONSTANTS.TAXONOMIAS_FILTROS) {
    CONSTANTS.emptyFilters[tax.id] = [];
}

export default CONSTANTS;
