import { useRef, useReducer, useState, useEffect, useCallback } from 'react';
import {
    Row,
    Col,
    Alert
} from 'react-bootstrap';
import {useLocation} from "react-router-dom";
import Mapa from '../components/Mapa';
import BuscaTexto from '../components/BuscaTexto';
import ModalSobre from '../components/ModalSobre';
import Sidebar from '../components/Sidebar';
import CONSTANTS from '../constants';
import {
    resgataOpcoes,
    fazBusca,
    fazFiltragem
    // getIPHANLayerFeatures
} from '../api';

export default function MainScreen(props) {
    const initialState = {
        showSidebar: true,
        isMobile: true,
        filters: CONSTANTS.emptyFilters,
        opcoes: null,
        opcoesVisiveis: null,
        geoJSON: null,
        geoJSONVisivel: null,
        fichas: [],
        fichasVisiveis: [],
        openModalSobre: false
    };
    const reducer = (state, newState) => {
        const ret = {
            ...state,
            ...newState,
            filters: {
                ...state.filters,
                ...newState.filters
            }
        };
        return ret;
    };
    const [state, setState] = useReducer(reducer, initialState);
    const mobileEventListener = useRef(null);
    const mounted = useRef(true);
    const location = useLocation();
    const [open, setOpen] = useState(true);

    const updateMobile = (isMobile) => {
        let mql = window.matchMedia('(max-width: 992px)');
        if (isMobile !== mql.matches) {
            setState({isMobile: mql.matches});
            setOpen(!mql.matches);
        }
    }

    useEffect(() => {
        const init = () => {
            mounted.current = false;
            const {fichas, geoJSON} = fazBusca();
            // getIPHANLayerFeatures().then((fichas) => {console.log({CORS: fichas})});
            const opcoes = resgataOpcoes(fichas);
            // console.log(fichas.filter(f => (f.datacao_inicial > 1919)));

            let newState = {
                opcoes: opcoes,
                opcoesVisiveis: opcoes,
                fichas: fichas,
                fichasVisiveis: fichas,
                geoJSON: geoJSON,
                geoJSONVisivel: geoJSON
            };

            setState(newState);

            updateMobile();
            mobileEventListener.current = window.addEventListener("resize", () => updateMobile(state.isMobile));

            return () => {
                window.removeEventListener("resize", mobileEventListener.current);
                mounted.current = false;
            }
        }
        if (mounted.current) {
            init();
        }
    }, [state.isMobile, location.search, mobileEventListener, mounted]);

    const updateFilters = useCallback((filters) => {
        const newFilters = {
            ...state.filters,
            ...filters
        };
        localStorage.setItem('modoVisualizacao', newFilters.modo);
        const {fichasVisiveis, geoJSONVisivel, opcoesVisiveis} = fazFiltragem(newFilters, state.fichas, state.geoJSON);
        setState({
            filters,
            fichasVisiveis,
            opcoesVisiveis,
            geoJSONVisivel
        });

    }, [state.filters, state.fichas, state.geoJSON]);

    return (
        <>
            <Row>
                {state.isMobile
                    ? <Sidebar
                        open = {open}
                        id = "sidebar"
                        key = "sidebar"
                        constants = {CONSTANTS}
                        filters = {state.filters}
                        opcoes = {state.opcoes}
                        onChange = {(filters) => {updateFilters(filters)}}
                        onSetClose = {() => {setOpen(false)}}
                        isMobile = {true}
                    />
                    : <Col xs={ 12 } md={ 4 } lg={ 3 }
                        as={ Sidebar }
                        open = {true}
                        id = "sidebar"
                        key = "sidebar"
                        constants = {CONSTANTS}
                        filters = {state.filters}
                        opcoes = {state.opcoes}
                        onChange = {(filters) => {updateFilters(filters)}}
                    />
                }

                <Col xs={ 12 } md={ 8 } lg={ 9 }>
                    {state.fichasVisiveis.length > 0
                        ? <Mapa
                            key = 'mapa'
                            filters = {state.filters}
                            fichas = {state.fichasVisiveis}
                            totalFichas = {state.fichas}
                            geoJSON = {state.geoJSONVisivel}
                            icones = {state.icones}
                            opcoes = {state.opcoesVisiveis}
                            onChange = {(filters) => {updateFilters(filters)}}
                            onOpenModalSobre = {() => {
                                if (!state.openModalSobre){
                                    setState({openModalSobre: true});
                                }
                            }}
                            isMobile={state.isMobile}
                            onSetFiltersOpen = {() => {
                                setOpen(true);
                            }}
                        />
                        : <Alert key="semResultados" variant="primary" className="semResultados">
                            Não há resultados para o(s) filtro(s) escolhido(s)
                        </Alert>
                    }
                </Col>
            </Row>
            <ModalSobre
                openModalSobre = {state.openModalSobre}
                onChangeOpenModalSobre = {(open) => {setState({openModalSobre: open})}}
            />
            <BuscaTexto
                onChange={(q) => {
                    updateFilters({q});
                }}
                onSetOpen={() => {
                    setOpen(true);
                }}
                q={state.filters.q}
                isMobile={state.isMobile}
            />
        </>
    );
}
