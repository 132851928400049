import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CONSTANTS from '../constants';

export default function SelectTax(props) {
    const extraProps = {};
    if (props.tax.hasOwnProperty('relatedTaxes')) {
        extraProps.groupBy = (opcao) => {
            return CONSTANTS.RECORTES_TERRITORIAIS[opcao.tax].nome;
        };
    }

    const updateTaxFilters = (selection) => {
        const newFilters = {[props.tax.slug]: selection};
        props.changeFilter(newFilters);
    };

    const taxSlug = props.tax.slug;

    return (
        <Autocomplete
            key={`autocomplete_${taxSlug}`}
            id={`autocomplete_${taxSlug}`}
            size='small'
            disablePortal
            options={props.opcoesTax}
            fullWidth={true}
            value={props.filtersTax}
            multiple={true}
            autoHighlight
            renderInput={(params) => <TextField {...params} label={props.tax.placeholder} />}
            getOptionLabel={(option) => option ? option.nome : ""}
            renderOption={(props, option) => {
                return (
                    <li
                        {...props}
                        className={props.className + ` ${taxSlug} ${taxSlug}_${option.id}`}
                        key={option.hasOwnProperty('tax') ? `${option.tax}_${option.id}` : option.id.toString()}
                    >
                        {option.nome}
                        {option.sub &&
                            <div className="selectTaxSub">
                                {option.sub}
                            </div>
                        }
                    </li>
                );
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, selection) => {
                updateTaxFilters(selection);
            }}
            {...extraProps}
        />
    );
}
