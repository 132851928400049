// import axios from 'axios';
// import querystring from 'querystring';
import CONSTANTS from './constants';
import {
    preparaParaBusca,
    getQuadrantes,
    getReferencias,
    getRelated,
    ordena,
    formataDatacao
} from './utils/utils';
import * as SHAPES from './geojson/index';

// export const getIPHANLayerFeatures = async (params) => {
//     const url = `${CONSTANTS.API.mapaNimuendaju}/wfs`;
//     let args = {
//         version: '2.0.0',
//         typename: 'Nimuendaju:tg_tribo_view',
//         service: 'WFS',
//         request: 'GetFeature',
//         srsname: 'EPSG:4674',
//         outputFormat: 'application/json',
//         maxFeatures: '50000'
//     };
//
//     const queryString = querystring.stringify(args);
//
//     // console.log({queryString, filters});
//     try {
//         const { data } = await axios.get(`${url}?${queryString}`);
//         return data;
//     } catch (error) {
//         console.log(error);
//     }
// };

export const fazBusca = (filters = null) => {
    return {
        geoJSON: SHAPES.nimuendaju,
        fichas: SHAPES.nimuendaju.features.map((feature) => {
            const props = feature.properties;
            let localizacao = [];
            for (let relatedTaxId of CONSTANTS.TAXONOMIAS_FILTROS[0].relatedTaxes) {
                const recorte = CONSTANTS.RECORTES_TERRITORIAIS[relatedTaxId];
                const items = getRelated(
                    props.id_tribo,
                    relatedTaxId,
                    recorte.manyToMany.field,
                    recorte.idField
                )
                localizacao = localizacao.concat(items.map(item => (
                    {
                        id: item[recorte.idField],
                        nome: item[recorte.nomeField],
                        tax: relatedTaxId
                    }
                )));
            }
            return {
                ...props,
                nome: props.nome.toUpperCase(),
                localizacao: localizacao,
                referencias: getReferencias(props.id_tribo, true),
                quadrantes: getQuadrantes(props.id_tribo, true),
                datacaoInicial: formataDatacao(props.datacao_inicial, 'datacaoInicial'),
                datacaoFinal: formataDatacao(props.datacao_final, 'datacaoFinal')
            };
        }
    )};
};

export const fazFiltragem = (filters, fichas, geoJSON) => {
    // console.log(filters);
    let fichasVisiveis = [];

    const q = (filters.hasOwnProperty('q') && filters.q !== '')
        ? preparaParaBusca(filters.q)
        : null;

    fichasVisiveis = fichas.filter((ficha) => {
        let passedFilter = true;
        for (let tax of CONSTANTS.TAXONOMIAS_FILTROS) {
            if (filters.hasOwnProperty(tax.slug) && filters[tax.slug].length > 0) {

                passedFilter = false;

                if (tax.hasOwnProperty('manyToMany')) {
                    const selectedIds = filters[tax.slug].map(item => item.id);
                    passedFilter = getRelated(ficha.id_tribo, tax.slug, tax.manyToMany.field).some(item => selectedIds.includes(item));
                    if (!passedFilter) {
                        return false;
                    }
                    continue;
                }

                passedFilter = (tax.hasOwnProperty('relatedTaxes'))
                    ? ficha[tax.slug]
                        .map(item => item.id)
                        .some(id => filters[tax.slug].map(item => item.id).includes(id))
                    : filters[tax.slug].map(item => item.nome).includes(ficha[tax.slug]);

                if (!passedFilter) {
                    return false;
                }
            }
        }

        if (!q || !passedFilter) {
            return passedFilter;
        }

        for (let field of CONSTANTS.SEARCH_FIELDS) {
            const alvo = preparaParaBusca(ficha[field]);
            if (alvo.includes(q)) {
                return true;
            }
        }

        for (let tax of CONSTANTS.TAXONOMIAS_FILTROS) {
            if (tax.hasOwnProperty('manyToMany')) {
                const relatedLabels = getRelated(ficha.id_tribo, tax.slug, tax.manyToMany.field, tax.manyToMany.field, tax.manyToMany.label);
                for (let label of relatedLabels) {
                    if (preparaParaBusca(label).includes(q)) {
                        return true;
                    }
                }
                continue;
            }

            if (ficha.hasOwnProperty(tax.slug)) {
                const alvo = preparaParaBusca(ficha[tax.slug]);
                if (alvo.includes(q)) {
                    return true;
                }
            }
        }

        return false;
    });

    // const opcoesVisiveis = resgataOpcoes(fichasVisiveis);
    const opcoesVisiveis = {};

    const idsVisiveis = fichasVisiveis.map((ficha) => {return ficha.id_tribo});
    const geoJSONVisivel = {
        ...geoJSON,
        features: geoJSON.features.filter((feature) => {
            return idsVisiveis.includes(feature.properties.id_tribo);
        })
    };
    return {fichasVisiveis, geoJSONVisivel, opcoesVisiveis};
};

export const resgataOpcoes = (fichas) => {
    const opcoes = {
        quadrantes: SHAPES.quadrantes.features.map((feature) => {
            return {
                id: feature.properties.id_quadrante,
                nome: feature.properties.nome
            };
        }),
        localizacao: [],
        referencias: [],
        nome: [],
        familia: [],
        tipo_sede: [],
        tipo_tribo: [],
        datacaoInicial: [],
        datacaoFinal: []
    };

    for (let ficha of fichas) {
        if (opcoes.nome.filter((f) => {return f.nome === ficha.nome}).length === 0) {
            opcoes.nome.push({
                id: ficha.id_tribo,
                nome: ficha.nome
            });
        }

        if (opcoes.familia.filter((f) => {return f.nome === ficha.familia}).length === 0) {
            opcoes.familia.push({
                id: ficha.id_familia_linguistica,
                nome: ficha.familia
            });
        }

        if (opcoes.tipo_sede.filter((f) => {return f.nome === ficha.tipo_sede}).length === 0) {
            opcoes.tipo_sede.push({
                id: ficha.id_tipo_sede,
                nome: ficha.tipo_sede
            });
        }

        if (opcoes.tipo_tribo.filter((f) => {return f.nome === ficha.tipo_tribo}).length === 0) {
            opcoes.tipo_tribo.push({
                id: ficha.id_tipo_tribo,
                nome: ficha.tipo_tribo
            });
        }

        const matchesInicial = opcoes.datacaoInicial.filter(f => (ficha.datacaoInicial.map(d => (d.id)).includes(f.id))).length;
        if (
            ficha.datacaoInicial.length > 0 &&
            matchesInicial <= 1
        ) {
            if (matchesInicial === 0) {
                // Século:
                opcoes.datacaoInicial.push(ficha.datacaoInicial[0]);
            }
            // Década:
            opcoes.datacaoInicial.push(ficha.datacaoInicial[1]);
        }

        const matchesFinal = opcoes.datacaoFinal.filter(f => (ficha.datacaoFinal.map(d => (d.id)).includes(f.id))).length;
        if (
            ficha.datacaoFinal.length > 0 &&
            matchesFinal <= 1
        ) {
            if (matchesFinal === 0) {
                // Século:
                opcoes.datacaoFinal.push(ficha.datacaoFinal[0]);
            }
            // Década:
            opcoes.datacaoFinal.push(ficha.datacaoFinal[1]);
        }

        const referencias = getReferencias(ficha.id_tribo);
        for (let referencia of referencias) {
            if (!opcoes.referencias.map((opcao) => {return opcao.id}).includes(referencia.id_referencia)) {
                opcoes.referencias.push({
                    id: referencia.id_referencia,
                    nome: (referencia.titulo) ? referencia.titulo : referencia.autoria,
                    sub: (referencia.titulo) ? referencia.autoria : null
                });
            }
        }

        let relatedTaxOrd = 0;
        for (let relatedTaxId of CONSTANTS.TAXONOMIAS_FILTROS[0].relatedTaxes) {
            const recorte = CONSTANTS.RECORTES_TERRITORIAIS[relatedTaxId];
            const relatedOptions = getRelated(
                ficha.id_tribo,
                relatedTaxId,
                recorte.manyToMany.field,
                recorte.idField
            );
            for (let relatedOption of relatedOptions) {
                const id = relatedOption[recorte.idField]
                if (!opcoes.localizacao.map(opcao => opcao.id).includes(id)) {
                    const nome = (recorte.hasOwnProperty('parentField'))
                        ? `${relatedOption[recorte.nomeField]} - ${relatedOption[recorte.parentField]}`
                        : relatedOption[recorte.nomeField]
                    opcoes.localizacao.push({
                        id: id,
                        nome: nome,
                        tax: relatedTaxId,
                        taxOrd: relatedTaxOrd
                    });
                }
            }
            relatedTaxOrd ++;
        }
    }

    for (let campo of Object.keys(opcoes)) {
        opcoes[campo] = opcoes[campo].sort(ordena('nome'));
        if (opcoes[campo][0].hasOwnProperty('taxOrd')) {
            opcoes[campo] = opcoes[campo].sort(ordena('taxOrd'));
        }
    }

    return opcoes;
};
