import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-drawer/lib/style.css';
import './css/App.css';
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import MainScreen from "./pages/MainScreen";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEyeSlash, faEye, faSearch, faInfoCircle, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faEyeSlash, faEye, faSearch, faInfoCircle, faFilter, faTimes);

function App() {
    return (
        <BrowserRouter>
            <Container fluid className="p-0">
                <MainScreen />
            </Container>
        </BrowserRouter>
    );
}

export default App;
