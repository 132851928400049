import React from 'react';
import { useRef } from 'react';
import {
    Collapse,
    Form,
    Container,
    Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from 'react-bootstrap-drawer';
import CONSTANTS from '../constants';
import SelectTax from './SelectTax';
import mapaSidebarHeader from '../imgs/mapaSidebarHeader.jpg';

export default function Sidebar(props) {
    // console.log({SidebarProps: props});
    const filtersRef = useRef(null);
	// const handleToggle = () => setOpen(!props.open);

    if (JSON.stringify(props.filters) !== filtersRef.current) {
        filtersRef.current = JSON.stringify(props.filters);
    }

    const changeFilter = (newFilter) => {
        props.onChange(newFilter);
        if (props.isMobile) {
            props.onSetClose();
        }
    }

    const resetFilters = () => {
        changeFilter(CONSTANTS.emptyFilters);
    }

    return (
        <Drawer className={ props.className } id={props.id}>
			<Collapse in={ props.open }>
				<Drawer.Overflow>
					<Drawer.ToC>
                        {props.isMobile
                            ? <Button
                                variant="outline-secondary"
                                className="filtersCloseBtn"
                                onClick={() => props.onSetClose()}
                            >
                                <FontAwesomeIcon icon="times"/>
                            </Button>
                            : <img src={mapaSidebarHeader} alt="Mapa Etno-Histórico de Curt Nimuendaju" />
                        }
                        <Drawer.Nav>
                            {props.opcoes &&
                                <Container>
                                    <Form>
                                        <Form.Group className="mt-3">
                                            <SelectTax
                                                key={`selectTax_${CONSTANTS.TAXONOMIAS_FILTROS[0].slug}`}
                                                tax={CONSTANTS.TAXONOMIAS_FILTROS[0]}
                                                isMobile={false}
                                                filtersTax={props.filters[CONSTANTS.TAXONOMIAS_FILTROS[0].slug]}
                                                opcoesTax={props.opcoes[CONSTANTS.TAXONOMIAS_FILTROS[0].slug]}
                                                changeFilter={changeFilter}
                                            />
                                        </Form.Group>

                                        {CONSTANTS.TAXONOMIAS_FILTROS.map((tax, index) => (
                                            <>
                                            {index > 0 &&
                                                <Form.Group className="mt-1">
                                                    <SelectTax
                                                        key={`selectTax_${tax.slug}`}
                                                        tax={tax}
                                                        isMobile={false}
                                                        filtersTax={props.filters[tax.slug]}
                                                        opcoesTax={props.opcoes[tax.slug]}
                                                        changeFilter={changeFilter}
                                                    />
                                                </Form.Group>
                                            }
                                            </>
                                        ))}
                                    </Form>
                                    <div className="d-grid gap-2 mt-4">
                                        <Button
                                            className="botaoLimparFiltros"
                                            variant="success"
                                            onClick={resetFilters}
                                        >
                                            Limpar Filtros
                                        </Button>
                                    </div>
                                </Container>
                            }
                        </Drawer.Nav>
					</Drawer.ToC>
				</Drawer.Overflow>
			</Collapse>
		</Drawer>
    );
}
