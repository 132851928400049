import { useRef } from 'react';
import {
    Container,
    InputGroup,
    FormControl
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function BuscaTexto(props) {

    const inputRef = useRef(null);

    if (inputRef.current) {
        inputRef.current.value = props.q;
    }

    return (
        <Container id="BuscaTexto">
            <InputGroup>
                <InputGroup.Text
                    className="searchBtn"
                    onClick={() => props.onChange(inputRef.current.value)}
                >
                    <FontAwesomeIcon icon="search"/>
                </InputGroup.Text>
                <FormControl
                    ref={inputRef}
                    placeholder="Busca livre"
                    aria-label="Busca livre"
                    aria-describedby="basic-addon1"
                    onKeyPress={(e) => {
                        if (e.which === 13) {
                            e.target.blur();
                            props.onChange(e.target.value);
                        }
                    }}
                    onBlur={(e) => {}}
                />
                {props.isMobile &&
                    <InputGroup.Text
                        className="filtersBtn"
                        onClick={() => props.onSetOpen()}
                    >
                        <FontAwesomeIcon icon={"filter"} />
                        Filtros
                    </InputGroup.Text>
                }
            </InputGroup>
        </Container>
    );
}
