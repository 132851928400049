import { useState, useRef } from 'react';
// import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import {
    useMapEvents,
    MapContainer,
    MapConsumer,
    WMSTileLayer,
    TileLayer,
    ZoomControl,
    LayersControl,
    GeoJSON,
    Pane,
    FeatureGroup
} from 'react-leaflet';
import {
    Container
} from 'react-bootstrap';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import CONSTANTS from '../constants';
import PopupFicha from './PopupFicha';
import MapaNimuendaju from './MapaNimuendaju';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getFicha} from '../utils/utils';
import '../css/mapa.css';
import * as SHAPES from '../geojson/index';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const ModalSobreControlBotao = (props) => {
    return (
        <Container
            className="leaflet-control-layers modalSobreControlBotao"
            onClick={() => {
                props.onChange();
            }}
        >
            <FontAwesomeIcon icon={"info-circle"} className="me-2" />
            Informações sobre a Plataforma Nimuendajú
        </Container>
    )
};

const ResultadosControl = (props) => {
    const filtrosAplicados = [];
    if (props.filters.q !== "") {
        filtrosAplicados.push({
            label: "Busca livre",
            value: props.filters.q
        });
    }
    for (let taxSlug of Object.keys(props.filters)) {
        if (['q', 'modo'].includes(taxSlug) || props.filters[taxSlug].length === 0) {
            continue;
        }

        filtrosAplicados.push({
            key: taxSlug,
            label: CONSTANTS.TAXONOMIAS_FILTROS.find(tax => tax.id === taxSlug).label,
            value: props.filters[taxSlug].map(filter => (filter.nome)).join(', ')
        });
    }
    return (
        <Container
            key="resultadosControl"
            className={`leaflet-control-scale-line resultadosControl${props.isMobile ? ' isMobile' : ''}`}
            onClick={() => {
                if (props.isMobile) {
                    props.onSetFiltersOpen();
                }
            }}
        >
            <span>{props.qtde} {(props.qtde === 1) ? "resultado" : "resultados"}</span>
            {filtrosAplicados.length > 0 &&
                <Container className="filtrosAplicados">
                    {filtrosAplicados.map((filtroAplicado, index) => (
                        <span key={filtroAplicado.key}>
                            <b>{filtroAplicado.label}: </b>{filtroAplicado.value}
                            {index < (filtrosAplicados.length - 1) &&
                                <br />
                            }
                        </span>
                    ))}
                </Container>
            }
        </Container>
    )
};

export default function Mapa(props) {
    // console.log({MapaPropsAreasTematicas: props.filters.areas_tematicas});
    const mapRef = useRef(null);
    const filtrosTaxonomia = useRef(null);
    const [ficha, setFicha] = useState(null);
    const geoFeatures = useRef({});
    const filtersRef = useRef(null);
    const modalSobreControlRef = useRef(false);
    const resultadosControlRef = useRef(false);
    let bounds;

    // const modoMapa = (CONSTANTS.LOCALSTORAGE.modoMapa)
    //     ? CONSTANTS.LOCALSTORAGE.modoMapa
    //     : CONSTANTS.MODO_MAPA.MAPA.id;

    if (JSON.stringify(props.filters) !== filtersRef.current) {
        filtersRef.current = JSON.stringify(props.filters);
        geoFeatures.current = {
            markers: [],
            qtde: {}
        };
    }

    const updateFilters = (newFilters) => {
        // console.log({MapaUpdateFilters: newFilters});
        props.onChange(newFilters);
    };

    const EventListeners = () => {
        useMapEvents({
            click: (e) => {
                if (typeof e.originalEvent.target.className !== 'string') {
                    return;
                }
                const classNames = e.originalEvent.target.className.split(' ');
                if (classNames.includes('leaflet-container') || classNames.includes('btn-close')) {
                    unSelectFicha();
                }
            }
        });
        return null;
    };

    const whenReadyHandler = (e) => {
        e.target.on('baselayerchange', (changed) => {
            for (let modo of Object.keys(CONSTANTS.MODO_MAPA)) {
                if (CONSTANTS.MODO_MAPA[modo].nome === changed.name) {
                    localStorage.setItem('modoMapa', CONSTANTS.MODO_MAPA[modo].id);
                    break;
                }
            }
        });
    };

    const eachOverlayStyle = (feature) => {
        return {
            fillColor: 'rgba(132, 63, 126, 1)',
            weight: 3,
            opacity: 0.4,
            color: 'rgba(60, 30, 60, 1)',
            dashArray: "3",
            fillOpacity: 0.4
        };
    };

    const mainMapStyles = (feature) => {
        return {
            opacity: 0,
            color: 'rgba(100, 0, 0, 1)',
            fillOpacity: 0,
            weight: 10
        };
    };

    const onEachFeature = (feature, layer) => {
        layer.on({
            click: (e) => {
                setFicha(getFicha(e.target.feature.id));
                mapRef.current.fitBounds(e.target.getBounds());
            },
            mouseover: (e) => {
                e.target.setStyle({
                    opacity: 0.4,
                });
                const ficha = getFicha(e.target.feature.id);
                layer.bindTooltip(`${ficha.nome} (família linguística "${ficha.familia}")`);
                layer.openTooltip();
            },
            mouseout: (e) => {
                e.target.setStyle({
                    opacity: 0
                });
                // layer.unbindTooltip();
                // layer.closeTooltip();
            },
        });
    };

    const eachOverlayFeature = (feature, layer, camada) => {
        const props = feature.properties;
        // console.log(props);
        // layer.on({
        //     click: (e) => {
        //         console.log(feature);
        //     }
        // });
        const key = `popup_${camada.id}_${props[camada.fieldId]}`;
        const body = camada.popupBody.filter((item) => {
            return props.hasOwnProperty(item.fieldName) && props[item.fieldName] !== "";
        }).map((item) => {
            return `<p><strong>${item.label}:</strong> ${props[item.fieldName]}</p>`;
        }).join('');

        const PopupContent = `
            <Popup
                key=${key}
                class='popupCamada'
            >
                <h5>${camada.nome}: ${props[camada.popupFieldTitle]}</h5>
                ${body}
            </Popup>
        `;
        layer.bindPopup(PopupContent);
    };

    const unSelectFicha = () => {
        mapRef.current.eachLayer((layer) => {
            if (layer.hasOwnProperty('feature')) {
                layer.setStyle({
                    color: '#3388ff'
                });
            }
        });
        setFicha(null);
    }

    return (
            <MapContainer
                center={[-15.7941, -47.8825]}
                zoom={4}
                maxZoom={19}
                zoomControl={false}
                scrollWheelZoom={true}
                whenReady={whenReadyHandler}
            >
                <LayersControl position="topleft">
                    <>
                    {Object.keys(CONSTANTS.CAMADAS).map((camadaId) => (
                        <LayersControl.Overlay
                            checked={false}
                            name={CONSTANTS.CAMADAS[camadaId].plural}
                            key={CONSTANTS.CAMADAS[camadaId].id}
                        >
                            <FeatureGroup>
                                {CONSTANTS.CAMADAS[camadaId].hasOwnProperty('WMS')
                                ?
                                    <WMSTileLayer
                                        key={CONSTANTS.CAMADAS[camadaId].WMS.id}
                                        layers={CONSTANTS.CAMADAS[camadaId].WMS.layers}
                                        url={CONSTANTS.CAMADAS[camadaId].WMS.url}
                                        attribution={CONSTANTS.CAMADAS[camadaId].WMS.attrs}
                                        transparent={true}
                                        format="image/png"
                                    />
                                :
                                    <GeoJSON
                                        key={`${camadaId}Overlay`}
                                        data={SHAPES[CONSTANTS.CAMADAS[camadaId].id]}
                                        style={eachOverlayStyle}
                                        onEachFeature={(feature, layer) => {eachOverlayFeature(feature, layer, CONSTANTS.CAMADAS[camadaId])}}
                                    />
                                }
                            </FeatureGroup>
                        </LayersControl.Overlay>
                    ))}
                    </>
                </LayersControl>

                <TileLayer
                    key="MAPALayer"
                    attribution={CONSTANTS.MODO_MAPA.MAPA.attrs}
                    url={CONSTANTS.MODO_MAPA.MAPA.url}
                />

                <ZoomControl position="topleft"/>

                {ficha != null &&
                    <PopupFicha
                        ficha={ficha}
                        opcoes={props.opcoes}
                        onChange={(filters) => {
                            updateFilters(filters);
                            unSelectFicha();
                        }}
                    />
                }

                <EventListeners />

                <MapConsumer>
                    {(map) => {
                        mapRef.current = map;
                        let novosFiltrosTaxonomia = {...props.filters};
                        novosFiltrosTaxonomia = JSON.stringify(novosFiltrosTaxonomia);

                        if (
                            !filtrosTaxonomia.current ||
                            filtrosTaxonomia.current !== novosFiltrosTaxonomia
                        ) {
                            unSelectFicha();
                            setTimeout(() => {
                                bounds = new L.latLngBounds();
                                map.eachLayer((layer) => {
                                    if (layer.hasOwnProperty('feature')) {
                                        bounds.extend(layer.getBounds());
                                    }
                                });
                                if (Object.keys(bounds).length > 0) {
                                    map.fitBounds(bounds);
                                }
                            }, 100);
                        }
                        filtrosTaxonomia.current = novosFiltrosTaxonomia;

                        if (!modalSobreControlRef.current) {
                            L.Control.ModalSobre = L.Control.extend({
                                options: {
                                    position: 'topright',
                                },
                                onAdd: function (map) {
                                    let controlDiv = L.DomUtil.create('div', '');
                                    ReactDOM.render(
                                        <ModalSobreControlBotao
                                            onChange={() => props.onOpenModalSobre()}
                                        />,
                                        controlDiv
                                    );
                                    return controlDiv;
                                }
                            });
                            map.addControl(new L.Control.ModalSobre());
                            modalSobreControlRef.current = true;
                        }

                        if (resultadosControlRef.current) {
                            map.removeControl(resultadosControlRef.current);
                        }
                        L.Control.Resultados = L.Control.extend({
                            options: {
                                position: 'bottomright',
                            },
                            onAdd: function (map) {
                                let controlDiv = L.DomUtil.create('div', '');
                                ReactDOM.render(
                                    <ResultadosControl
                                        qtde={props.fichas.length}
                                        filters={props.filters}
                                        isMobile={props.isMobile}
                                        onSetFiltersOpen={props.onSetFiltersOpen}
                                    />,
                                    controlDiv
                                );
                                return controlDiv;
                            }
                        });
                        resultadosControlRef.current = new L.Control.Resultados();
                        map.addControl(resultadosControlRef.current);

                        return null;
                    }}
                </MapConsumer>

                {props.fichas.length > 0 &&
                    <Pane name="mapaNimendajuPane" style={{ zIndex: 600 }}>
                        <GeoJSON
                            key={'g'+Date.now()}
                            data={props.geoJSON}
                            interactive={true}
                            onEachFeature={onEachFeature}
                            style={mainMapStyles}
                        />
                        <MapaNimuendaju
                            key="MapaNimuendaju"
                            totalFichas = {props.totalFichas}
                            fichas = {props.fichas}
                            filters = {props.filters}
                        />
                    </Pane>
                }
            </MapContainer>
    );
}


// {Object.keys(CONSTANTS.MODO_MAPA).map((modo) => (
//     <LayersControl.BaseLayer
//         checked={CONSTANTS.MODO_MAPA[modo].id === modoMapa}
//         name={CONSTANTS.MODO_MAPA[modo].nome}
//         key={modo}
//     >
//         {CONSTANTS.MODO_MAPA[modo].hasOwnProperty('wms')
//             ? <WMSTileLayer
//                 layers={CONSTANTS.MODO_MAPA[modo].layers}
//                 url={CONSTANTS.MODO_MAPA[modo].url}
//                 attribution={CONSTANTS.MODO_MAPA[modo].attrs}
//                 maxZoom={CONSTANTS.MODO_MAPA[modo].maxZoom}
//                 minZoom={CONSTANTS.MODO_MAPA[modo].minZoom}
//                 transparent={true}
//                 format="image/png"
//             />
//             : <TileLayer
//                 key={`${modo}Layer`}
//                 attribution={CONSTANTS.MODO_MAPA[modo].attrs}
//                 url={CONSTANTS.MODO_MAPA[modo].url}
//             />
//         }
//     </LayersControl.BaseLayer>
// ))}
