import {
    WMSTileLayer
} from 'react-leaflet';
import CONSTANTS from '../constants';

export default function MapaNimuendaju(props) {
    const getFeatureIds = () => {
        return props.fichas.map((ficha) => {return ficha.id_tribo}).join(',');
    }
    const getNonFeatureIds = () => {
        const excluded = props.fichas.map(ficha => ficha.id_tribo);
        return props.totalFichas.filter(ficha => !excluded.includes(ficha.id_tribo)).map(ficha => ficha.id_tribo).join(',');
    }

    const getFilterAttributes = () => {
        if (props.fichas.length === props.totalFichas.length) {
            return {}
        }

        return (props.fichas.length < CONSTANTS.MAX_FICHAS_WMS_CQL)
            ? { featureId: getFeatureIds() }
            : { cql_filter: 'id_tribo NOT IN (' + getNonFeatureIds() + ')' };

        // if (props.filters.q !== '') {
        //     return (props.fichas.length < props.qtdeTotalFichas)
        //         ? { featureId: getFeatureIds() }
        //         : {};
        // }
        //
        // let CQLFilter = [];
        // for (let filtro of CONSTANTS.TAXONOMIAS_FILTROS) {
        //     if (props.filters.hasOwnProperty(filtro.slug) && props.filters[filtro.slug].length > 0) {
        //         if (filtro.hasOwnProperty('manyToMany')) {
        //             return (props.fichas.length < props.qtdeTotalFichas)
        //                 ? { featureId: getFeatureIds() }
        //                 : {};
        //         }
        //
        //         let CQLFilterIds = [];
        //         for (let selected of props.filters[filtro.slug]) {
        //             CQLFilterIds.push(selected.id);
        //         }
        //         CQLFilterIds.join(',')
        //         CQLFilter.push(`${filtro.idField} IN (${CQLFilterIds})`);
        //     }
        // }
        //
        // return (props.fichas.length < props.qtdeTotalFichas)
        //     ? { cql_filter: CQLFilter.join(' AND ') }
        //     : {};
    };

    const filterAttributes = getFilterAttributes();
    const key = Date.now();

    return (
        <WMSTileLayer
            key={key}
            layers={CONSTANTS.WMS_MAPA.layers}
            url={CONSTANTS.WMS_MAPA.url}
            attribution={CONSTANTS.WMS_MAPA.attrs}
            transparent={true}
            format="image/png"
            {...filterAttributes}
        />
    );
}
