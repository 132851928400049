import CONSTANTS from '../constants';
import * as SHAPES from '../geojson/index';

export function getFichaUrl(slug) {
    return `${CONSTANTS.HOME}/${slug}`;
}

export function getFicha(id) {
    for (let feature of SHAPES.nimuendaju.features) {
        if (feature.id === id) {
            return feature.properties;
        }
    }
    return null;
}

export function getReferencia(id_referencia) {
    for (let referencia of SHAPES.referencias.features) {
        if (referencia.properties.id_referencia === id_referencia) {
            return referencia.properties;
        }
    }
    return null;
}

export function getReferencias(id_tribo, isIdOnly = false) {
    return SHAPES.tribos_referencias.features.filter((tribo_referencia) => {
        return tribo_referencia.properties.id_tribo === id_tribo;
    }).map((tribo_referencia) => {
        return (isIdOnly)
            ? tribo_referencia.properties.id_referencia
            : getReferencia(tribo_referencia.properties.id_referencia);
    });
}

export function getMunicipio(id_municipio) {
    for (let municipio of SHAPES.municipio.features) {
        if (municipio.properties.ID === id_municipio) {
            return municipio.properties;
        }
    }
    return null;
}

export function getMunicipios(id_tribo, isIdOnly = false) {
    return SHAPES.tribos_municipio.features.filter((tribo_municipio) => {
        return tribo_municipio.properties.id_tribo === id_tribo;
    }).map((tribo_municipio) => {
        return (isIdOnly)
            ? tribo_municipio.properties.id_municipio
            : getMunicipio(tribo_municipio.properties.id_municipio);
    });
}

export function getQuadrante(id_quadrante) {
    for (let quadrante of SHAPES.quadrantes.features) {
        if (quadrante.properties.id_quadrante === id_quadrante) {
            return quadrante.properties;
        }
    }
    return null;
}

export function getQuadrantes(id_tribo, isIdOnly = false) {
    return SHAPES.tribos_quadrantes.features.filter((tribo_quadrante) => {
        return tribo_quadrante.properties.id_tribo === id_tribo;
    }).map((tribo_quadrante) => {
        return (isIdOnly)
            ? tribo_quadrante.properties.id_quadrante
            : getQuadrante(tribo_quadrante.properties.id_quadrante);
    });
}

export function getRelatedItem(id, related, relatedId, relatedLabel = null) {
    for (let feature of SHAPES[related].features) {
        if (feature.properties[relatedId] === id) {
            return (relatedLabel)
                ? feature.properties[relatedLabel]
                : feature.properties;
        }
    }
    return null;
}

export function getRelated(id_tribo, related, relatedId, relatedTableIdField = null, relatedTableLabelField = null) {
    return SHAPES[`tribos_${related}`].features.filter((feature) => {
        return feature.properties.id_tribo === id_tribo;
    }).map((feature) => {
        if (!relatedTableIdField) {
            return feature.properties[relatedId];
        }

        if (!relatedTableIdField) {
            relatedTableIdField = relatedId;
        }
        return getRelatedItem(feature.properties[relatedId], related, relatedTableIdField, relatedTableLabelField);
    });
}

export function getNomeTipoFicha(tipoSlug, plural = false) {
    for (let tipo of CONSTANTS.TIPOS_DE_FICHA) {
        if (tipo.slug === tipoSlug) {
            return (plural) ? tipo.plural : tipo.name;
        }
    }
}

export function getCorTipoFicha(tipoSlug) {
    for (let tipo of CONSTANTS.TIPOS_DE_FICHA) {
        if (tipo.slug === tipoSlug) {
            return tipo.cor;
        }
    }
}

export function getNomeTermoTaxonomia(taxonomia, taxSlug, opcoes) {
    for (let tax of opcoes[taxonomia]) {
        if (tax.slug === taxSlug) {
            return tax.name;
        }
    }
    return "";
}

export function getTermoTaxonomia(taxonomia, taxSlug, opcoes) {
    for (let tax of opcoes[taxonomia]) {
        if (tax.slug === taxSlug) {
            return tax;
        }
    }
    return "";
}

export function ordena(campo) {
    var sortOrder = 1;
    if(campo[0] === "-") {
        sortOrder = -1;
        campo = campo.substr(1);
    }
    return function (a,b) {
        var result = (a[campo] < b[campo]) ? -1 : (a[campo] > b[campo]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function ordenaLista(fichas, campo) {
    return fichas.sort(ordena(campo));
}

export function getResultadosPorTipo(ficha, tipo, taxonomia, resultados) {
    if (!ficha.tax.hasOwnProperty(taxonomia)) {
        return resultados;
    }
    for (let taxSlug of ficha.tax[taxonomia]) {
        if (!resultados.hasOwnProperty(taxSlug)) {
            resultados[taxSlug] = {};
        }
        if (!resultados[taxSlug].hasOwnProperty(tipo)) {
            for (let t of CONSTANTS.TIPOS_DE_FICHA) {
                resultados[taxSlug][t.slug] = 0;
            }
        }
        resultados[taxSlug][tipo] += 1;
    }
    return resultados;
}

export function getResultados(ficha, taxonomia, resultados) {
    if (!ficha.tax.hasOwnProperty(taxonomia)) {
        return resultados;
    }
    for (let taxSlug of ficha.tax[taxonomia]) {
        if (!resultados.hasOwnProperty(taxSlug)) {
            resultados[taxSlug] = 0;
        }
        resultados[taxSlug] += 1;
    }
    return resultados;
}

export function preparaParaBusca(str) {
    if (!str || typeof str !== 'string') {
        return '';
    }
    // str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(/\s+/g, '-').toLowerCase();
}

export function getFiltersFromURL(searchParams, opcoes) {
    let filters = CONSTANTS.emptyFilters;
    for (let param of searchParams) {
        const key = param[0];
        const value = param[1];

        if (['q', 'modo', 'recorteTerritorial'].includes(key)) {
            filters[key] = value;
            continue;
        }

        if (filters.hasOwnProperty(key)) {
            const term = getTermoTaxonomia(key, value, opcoes);
            if (term) {
                filters[key].push(term);
            }
        }
    }
    return filters;
}

export function hashCode(s) {
    return s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0);
}

function seculoRomano(anoRaw) {
    const seculo = Math.floor(Math.abs(parseInt(anoRaw))/100) + 1;
    switch (seculo) {
        case 14:
            return 'XIV';
        case 15:
            return 'XV';
        case 16:
            return 'XVI';
        case 17:
            return 'XVII';
        case 18:
            return 'XVIII';
        case 19:
            return 'XIX';
        case 20:
            return 'XX';
        case 21:
            return 'XXI';
        case 22:
            return 'XXII';
        case 23:
            return 'XXIII';
        default:
            return null;
    }
}

function formataDecada(anoRaw) {
    const decada = Math.floor(Math.abs(parseInt(anoRaw))/10);
    const anoInicio = decada * 10;
    const anoFim = anoInicio + 9;
    return `${anoInicio}-${anoFim}`;
}

export function formataDatacao(datacaoRaw, tax) {
    if (datacaoRaw === null || Math.abs(parseInt(datacaoRaw)) < 1500) {
        return [];
    }
    let datacao = [];
    const seculo = seculoRomano(datacaoRaw);
    const decada = formataDecada(datacaoRaw);
    datacao = [
        {
            id: seculo,
            nome: `Século ${seculo}`,
            tax: `${tax}Seculo`,
            taxOrd: Math.abs(parseInt(datacaoRaw))
        },
        {
            id: decada,
            nome: `Década ${decada}`,
            tax: `${tax}Decada`,
            taxOrd: Math.abs(parseInt(datacaoRaw)) + 10000
        }
    ];
    return datacao;
}
